import {
    Box,
    Button,
    GridItem,
    List,
    ListItem,
    SimpleGrid,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper,
    StepSeparator,
    StepStatus,
    StepTitle,
    Text,
    useSteps,
    VStack
} from "@chakra-ui/react";
import React from "react";
import UploadComparisonFiles from "../components/dian/UploadComparisonFiles";
import NomenclatureBox from "../components/dian/NomenclatureBox";
import ComparisonCard from "../components/dian/ComparisonCard";
import ComparisonDetail from "../components/dian/ComparisonDetail";
import {fetchComparison} from "../services/dian.services";
import {useAppDispatch, useAppSelector} from "../store/store";
import {changeNextAllowed, setComparisonResults} from "../actions/dian.actions";
import {ComparisonModel, DianComparisonResponse} from "../types/dian.types";


export default function Dian() {
    const steps = [
        {title: 'Subir archivos', description: 'DIAN vs. Intüipos'},
        {title: 'Resultados', description: 'Ver comparación'},
    ]

    const {activeStep, setActiveStep} = useSteps({
        index: 0,
        count: steps.length,
    });

    const {nextAllowed, comparisonResult} = useAppSelector((state) => state.dian)
    const dispatch = useAppDispatch()

    const retrieveStatus = (result: any) => {
        if (result["overall_match"] === true) {
            return "match"
        } else if (result['found'] === false) {
            return "not-found"
        } else {
            return "not-match"
        }
    }

    return (
        <Box height={"100%"} pb={200}>
            <Stepper index={activeStep}>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepIndicator>
                            <StepStatus
                                complete={<StepIcon/>}
                                incomplete={<StepNumber/>}
                                active={<StepNumber/>}
                            />
                        </StepIndicator>
                        <Box flexShrink='0'>
                            <StepTitle>{step.title}</StepTitle>
                            <StepDescription>{step.description}</StepDescription>
                        </Box>
                        <StepSeparator/>
                    </Step>
                ))}
            </Stepper>

            {activeStep === 0 ? (
                <Box height={"100%"}>
                    <Text mt={"35px"} whiteSpace={"pre"}>
                        Comparación entre reporte de facturación electrónica de la DIAN y el reporte de facturación de
                        Intüipos
                        (compras). {'\n\n'}
                        - El archivo de Intüipos debe ser el extracto de compras de Intüipos en formato .xlsx. {'\n'}
                        - El archivo de DIAN debe ser el reporte de facturación electrónica de la DIAN en formato
                        .xlsx {'\n'}
                        conteniendo únicamente una pestaña con la información de las facturas de compra.

                    </Text>

                    <VStack height={"70%"} justifyContent={"center"} spacing={10}>
                        <UploadComparisonFiles/>
                        <Button bg={"blue.400"} color={"white"} _hover={{bg: "blue.600"}}
                                isDisabled={!nextAllowed}
                                onClick={() => {
                                    fetchComparison().then((data: Array<ComparisonModel> | undefined) => {
                                        if (data) {
                                            dispatch(setComparisonResults(data))
                                            setActiveStep(1);
                                        }
                                    })
                                }}
                        >
                            Comparar
                        </Button>
                    </VStack>
                </Box>
            ) : (
                <Box height={"100%"}>
                    <SimpleGrid columns={3} mt={"45px"} spacing={"15px"} minHeight={"80%"}>
                        <GridItem colSpan={1}>
                            <Box bg={"lightgrey"} color={"white"} height={"100%"} borderRadius={"10px"}
                                 overflowY={"auto"}>
                                <NomenclatureBox/>
                                <List p={"10px"} spacing={3} height={"100px"}>
                                    {comparisonResult.map((result, index) => (
                                        <ListItem key={index}>
                                            <ComparisonCard
                                                folioDian={`${result.folio_dian}`}
                                                folioIntuipos={`${result.folio_intuipos}`}
                                                status={retrieveStatus(result)}
                                            />
                                        </ListItem>
                                    ))}
                                    {/*<ListItem>*/}
                                    {/*    <ComparisonCard folioDian={"312312321321"} folioIntuipos={"312312321321"}*/}
                                    {/*                    status={"matched"}/>*/}
                                    {/*</ListItem>*/}
                                </List>
                            </Box>
                        </GridItem>

                        <GridItem colSpan={2}>
                            <Box bg={"white"} height={"100%"} borderRadius={"10px"}
                                 overflowY={"auto"}>
                                <ComparisonDetail/>
                            </Box>
                        </GridItem>
                    </SimpleGrid>

                    <VStack mt={"30px"}>
                        <Button
                            onClick={() => {
                                dispatch(changeNextAllowed(false))
                                setActiveStep(0);
                            }}
                        >
                            Atrás
                        </Button>
                    </VStack>
                </Box>
            )}
        </Box>
    );
}