import {Box, Button, Input, Text, useToast, VStack} from "@chakra-ui/react";
import React, {useState} from "react";
import {fetchSiigoUploadPurchases} from "../../services/siigo.services";


export default function UploadPurchases() {
    const [file1Name, setFile1Name] = useState("");
    const [file2Name, setFile2Name] = useState("");
    const [file1Uploaded, setFile1Uploaded] = useState(false);
    const [file2Uploaded, setFile2Uploaded] = useState(false);
    const toast = useToast();

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
        if (e.target.files) {
            const file = e.target.files[0];
            if (!file) return;
            const mimeType = file.type;

            if (mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || mimeType === "application/vnd.ms-excel") {
                if (fileType === "intuipos") {
                    setFile1Name(file.name);
                    setFile1Uploaded(true);
                } else if (fileType === "dian") {
                    setFile2Name(file.name);
                    setFile2Uploaded(true);
                }
            } else {
                if (fileType === "intuipos") {
                    setFile1Name("Tipo de archivo no permitido");
                    setFile1Uploaded(false);
                } else if (fileType === "dian") {
                    setFile2Name("Tipo de archivo no permitido");
                    setFile2Uploaded(false);
                }
            }
        }
    };

    return (
        <Box height={"100%"}>
            <VStack height={"70%"} justifyContent={"center"}>
                <Text fontSize={"x-large"} fontWeight={"bold"}>
                    Subir facturas de compras a Siigo
                </Text>
                <Text>
                    Subir la interface de compras de Intüipos para que cada factura sea
                    subida a Siigo automáticamente.
                </Text>
                <Text mt={10}>Seleccionaste archivo {file1Name}</Text>
                <Button as="label" htmlFor="intuiposFile" cursor="pointer">
                    Seleccionar archivo Intuipos
                </Button>
                <Input
                    id="intuiposFile"
                    type="file"
                    onChange={(event) => handleFileUpload(event, "intuipos")}
                    display="none"
                />
                <Text mt={10}>Seleccionaste archivo {file2Name}</Text>
                <Button as="label" htmlFor="dianFile" cursor="pointer">
                    Seleccionar archivo DIAN
                </Button>
                <Input
                    id="dianFile"
                    type="file"
                    onChange={(event) => handleFileUpload(event, "dian")}
                    display="none"
                />
                <Button mt={10} colorScheme={(file1Uploaded && file2Uploaded) ? "green" : "blue"}
                        isDisabled={!(file1Uploaded && file2Uploaded)}
                        onClick={() => {
                            fetchSiigoUploadPurchases().then((data) => {
                                console.log({data})
                                toast({
                                    position: 'top-right',
                                    title: 'Archivo subido',
                                    description: "Enviaremos un correo cuando el proceso haya terminado.",
                                    status: 'success',
                                    duration: 9000,
                                    isClosable: true,
                                })
                            })
                        }}>
                    Subir archivo
                </Button>
            </VStack>
        </Box>
    )
}