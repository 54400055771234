import {Divider, Flex, GridItem, Icon, Text, Tooltip} from "@chakra-ui/react";
import {CheckIcon, CloseIcon} from "@chakra-ui/icons";

interface ComparisonDetailRowProps {
    description: string;
    value: string;
    intuipos: boolean;
    dian: boolean;
    intuiposValue: string;
    dianValue: string;
}


const ComparisonDetailRow = ({description, value, intuipos, dian, intuiposValue, dianValue}: ComparisonDetailRowProps) => {
    return (
        <>
            <GridItem colSpan={6}>
                <Divider/>
            </GridItem>
            <GridItem colSpan={2}>
                <Text>
                    {description}
                </Text>
            </GridItem>
            <GridItem colSpan={2}>
                <Text>
                    {value}
                </Text>
            </GridItem>
            <GridItem colSpan={1}>
                <Flex justifyContent={"center"}>
                    <Tooltip label={intuiposValue}>
                        <Icon as={intuipos ? CheckIcon : CloseIcon} color={intuipos ? "green" : 'red'}/>
                    </Tooltip>
                </Flex>
            </GridItem>
            <GridItem colSpan={1}>
                <Flex justifyContent={"center"}>
                    <Tooltip label={dianValue}>
                        <Icon as={dian ? CheckIcon : CloseIcon} color={dian ? "green" : "red"}/>
                    </Tooltip>
                </Flex>
            </GridItem>

        </>
    )
}

export default ComparisonDetailRow