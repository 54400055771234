import {ChakraProvider} from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import App from "./App"
import {Provider} from "react-redux";
import {store} from "./store/store";
import theme from "./utils/theme";

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
    <Provider store={store}>
        <ChakraProvider theme={theme}>
            {/*<React.StrictMode>*/}
            <App/>
            {/*</React.StrictMode>*/}
        </ChakraProvider>
    </Provider>
)

