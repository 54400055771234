import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    GridItem,
    Input,
    InputGroup,
    InputRightAddon,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
    useToast
} from "@chakra-ui/react";
import {Select} from "chakra-react-select";
import {fetchInventoryItems, updateInvoice} from "../../services/invoices.services";

export default function ItemsModal({invoice, onClose}: any) {
    const [inventoryItems, setInventoryItems] = useState<any[]>([])
    const [itemsOptions, setItemsOptions] = useState<any[]>([])
    const [invoiceItems, setInvoiceItems] = useState<any[]>(invoice.items || [])
    const toast = useToast();

    useEffect(() => {
        fetchInventoryItems().then((data) => {
            if (data) {
                setInventoryItems(data.inventory_items)
                let items: any[] = []
                data.inventory_items.forEach((item: any) => {
                    items.push({label: item.item_name, value: item.item_name})
                })
                setItemsOptions(items)
            }
        });
    }, []);

    const handleItemChange = (selectedOption: any, index: number) => {
        const item = inventoryItems.find((item: any) => item.item_name === selectedOption.value);
        if (item) {
            const updatedInvoiceItems = [...invoiceItems];
            updatedInvoiceItems[index] = {
                ...updatedInvoiceItems[index],
                unit: item.item_unit,
                inventory_item_name: item.item_name,
                inventory_id: item.id,
                item_role_id: item.item_role_id,
                item_role_name: item.item_role_name
            };
            setInvoiceItems(updatedInvoiceItems);
        }
    };

    const handleInputChange = (value: any, index: number, field: string) => {
        const updatedInvoiceItems = [...invoiceItems];
        updatedInvoiceItems[index] = {
            ...updatedInvoiceItems[index],
            [field]: field === 'quantity' || field === 'price_per_unit' || field === 'price' ? Number(value) : value,
        };
        setInvoiceItems(updatedInvoiceItems);
    };

    const handleTaxChange = (selectedOption: any, index: number, taxIndex: number) => {
        const updatedInvoiceItems = [...invoiceItems];
        const taxName = selectedOption.value;
        const updatedTaxes = {...updatedInvoiceItems[index].taxes};
        const oldTaxName = Object.keys(updatedTaxes)[taxIndex];
        const oldTaxAmount = updatedTaxes[oldTaxName]?.tax_amount || 0;
        delete updatedTaxes[oldTaxName];
        updatedTaxes[taxName] = {tax_amount: oldTaxAmount};
        updatedInvoiceItems[index] = {
            ...updatedInvoiceItems[index],
            taxes: updatedTaxes,
        };
        setInvoiceItems(updatedInvoiceItems);
    };

    const handleTaxAmountChange = (value: any, index: number, taxName: string) => {
        const updatedInvoiceItems = [...invoiceItems];
        const updatedTaxes = {...updatedInvoiceItems[index].taxes};
        updatedTaxes[taxName] = {tax_amount: Number(value)};
        updatedInvoiceItems[index] = {
            ...updatedInvoiceItems[index],
            taxes: updatedTaxes,
        };
        setInvoiceItems(updatedInvoiceItems);
    };

    const optionsMeasurement = [
        {label: "un", value: "un"},
        {label: "kg", value: "kg"},
        {label: "gr", value: "gr"},
        {label: "ml", value: "ml"},
        {label: "porción", value: "porción"},
        {label: "botella 750 c.c", value: "botella 750 c.c"},
        {label: "botella 375 c.c", value: "botella 375 c.c"},
        {label: "botella 1000 c.c", value: "botella 1000 c.c"},
        {label: "botella 500 c.c", value: "botella 500 c.c"},
        {label: "TABLETAS", value: "TABLETAS"},
        {label: "BT 700ML", value: "BT 700ML"},
        {label: "UND X 750", value: "UND X 750"}
    ];

    const optionsTaxes = [
        {label: "IVA 0%", value: "IVA 0%"},
        {label: "IVA 16%", value: "IVA 16%"},
        {label: "IVA 5%", value: "IVA 5%"},
        {label: "INC (COMPRAS)", value: "INC (COMPRAS)"},
        {label: "IVA (VAR)%", value: "IVA (VAR)%"},
        {label: "IVA 19%", value: "IVA 19%"},
        {label: "Bolsas", value: "Bolsas"},
        {label: "IC Porcentual", value: "IC Porcentual"},
        {label: "INC 9%", value: "INC 9%"},
        {label: "IBUA < 6gr/100 ml", value: "IBUA < 6gr/100 ml"},
        {label: "IBUA >= 6gr < 10gr / 100 ml", value: "IBUA >= 6gr < 10gr / 100 ml"},
        {label: "IBUA >= 10gr / 100 ml", value: "IBUA >= 10gr / 100 ml"},
        {label: "ICUI", value: "ICUI"},
        {label: "I.G.V 18", value: "I.G.V 18"}
    ];

    return (<> <ModalOverlay/>
            <ModalContent p={1}>
                <ModalHeader fontSize={'xl'} fontWeight={'bold'}>Productos</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    {invoiceItems.map((item: any, index: number) => {
                            let taxes = Object.keys(item.taxes);
                            return (
                                <Box mb={8} key={index}>
                                    <Text fontSize={"larger"} fontWeight={'bold'} mb={3}>{item.description}</Text>
                                    <SimpleGrid columns={2} spacing={1}>
                                        <GridItem display="flex" alignItems="center" justifyContent="start">
                                            <Box as="span" fontWeight="bold">Descripción:</Box>
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="center">
                                            <Select
                                                defaultValue={{
                                                    label: item.inventory_item_name,
                                                    value: item.inventory_item_name
                                                }}
                                                options={itemsOptions}
                                                onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                                isSearchable
                                            />
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="start">
                                            <Box as="span" fontWeight="bold">Cantidad:</Box>
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="center">
                                            <Input defaultValue={item.quantity} type={'number'}
                                                   onChange={(e) => handleInputChange(e.target.value, index, 'quantity')}/>
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="start">
                                            <Box as="span" fontWeight="bold">Precio unitario:</Box>
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="center">
                                            <InputGroup>
                                                <Input defaultValue={item.price_per_unit} type={'number'}
                                                       onChange={(e) => handleInputChange(e.target.value, index, 'price_per_unit')}/>
                                                <InputRightAddon>COP</InputRightAddon>
                                            </InputGroup>
                                        </GridItem>

                                        <GridItem display="flex" alignItems="center" justifyContent="start">
                                            <Box as="span" fontWeight="bold">Unidad medida:</Box>
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="center">
                                            <Select
                                                defaultValue={{label: item.unit, value: item.unit}}
                                                value={{label: item.unit, value: item.unit}}
                                                options={optionsMeasurement}
                                                isDisabled={true}
                                                isSearchable
                                            />
                                        </GridItem>

                                        <GridItem display="flex" alignItems="center" justifyContent="start">
                                            <Box as="span" fontWeight="bold">Total:</Box>
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="center">
                                            <InputGroup>
                                                <Input defaultValue={item.price} type={'number'}
                                                       onChange={(e) => handleInputChange(e.target.value, index, 'price')}/>
                                                <InputRightAddon>COP</InputRightAddon>
                                            </InputGroup>
                                        </GridItem>

                                        <GridItem display="flex" alignItems="center" justifyContent="start">
                                            <Box as="span" fontWeight="bold">Impuesto 1 nombre:</Box>
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="center">
                                            <Select
                                                defaultValue={{label: taxes[0], value: taxes[0]}}
                                                options={optionsTaxes}
                                                onChange={(selectedOption) => handleTaxChange(selectedOption, index, 0)}
                                                isSearchable
                                            />
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="start">
                                            <Box as="span" fontWeight="bold">Impuesto 1 valor:</Box>
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="center">
                                            <InputGroup>
                                                <Input
                                                    defaultValue={taxes.length > 0 ? item.taxes[taxes[0]].tax_amount : ''}
                                                    onChange={(e) => handleTaxAmountChange(e.target.value, index, taxes[0])}
                                                />
                                                <InputRightAddon>COP</InputRightAddon>
                                            </InputGroup>
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="start">
                                            <Box as="span" fontWeight="bold">Impuesto 2 nombre:</Box>
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="center">
                                            <Select
                                                defaultValue={{label: taxes[1], value: taxes[1]}}
                                                options={optionsTaxes}
                                                onChange={(selectedOption) => handleTaxChange(selectedOption, index, 1)}
                                                isSearchable
                                            />
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="start">
                                            <Box as="span" fontWeight="bold">Impuesto 2 valor:</Box>
                                        </GridItem>
                                        <GridItem display="flex" alignItems="center" justifyContent="center">
                                            <InputGroup>
                                                <Input
                                                    defaultValue={taxes.length > 1 ? item.taxes[taxes[1]].tax_amount : ''}
                                                    onChange={(e) => handleTaxAmountChange(e.target.value, index, taxes[1])}
                                                />
                                                <InputRightAddon>COP</InputRightAddon>
                                            </InputGroup>
                                        </GridItem>
                                    </SimpleGrid>
                                </Box>
                            )
                        }
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme={'blue'} onClick={
                        () => {
                            updateInvoice({invoice: {...invoice, items: invoiceItems}}).then((data) => {
                                onClose()
                                toast({
                                    position: 'top-right',
                                    title: 'Factura Actualizada',
                                    description: data.status === 'success' ? `` : "",
                                    status: data.status,
                                    duration: 12000,
                                    isClosable: true,
                                })
                            });
                        }
                    }>Actualizar items</Button>
                </ModalFooter>
            </ModalContent> </>
    )
}