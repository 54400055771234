import {createSlice} from '@reduxjs/toolkit'
import {ComparisonModel} from "../types/dian.types";


export interface DianState {
    nextAllowed: boolean
    comparisonResult: Array<ComparisonModel> | []
    comparisonSelected: string | null
}

const initialState: DianState = {
    nextAllowed: false,
    comparisonResult: [],
    comparisonSelected: null
}

export const dianSlice = createSlice({
    name: 'dian',
    initialState,
    reducers: {
        changeNextAllowed: (state, action) => {
            state.nextAllowed = action.payload
        },
        setComparisonResults: (state, action) => {
            state.comparisonResult = action.payload
        },
        setComparisonSelected: (state, action) => {
            state.comparisonSelected = action.payload
        }
    },
})

export default dianSlice.reducer