import {Box, Flex, Icon, Input, Text} from "@chakra-ui/react";
import {FaFileUpload} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../store/store";
import {changeNextAllowed} from "../../actions/dian.actions";

const UploadComparisonFiles = () => {
    const [file1Name, setFile1Name] = useState("");
    const [file2Name, setFile2Name] = useState("");
    const [file1Uploaded, setFile1Uploaded] = useState(false);
    const [file2Uploaded, setFile2Uploaded] = useState(false);

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (file1Uploaded && file2Uploaded) {
            dispatch(changeNextAllowed(true));
        }
    }, [file1Uploaded, file2Uploaded]);

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, setFileName: React.Dispatch<React.SetStateAction<string>>, setFileUploaded: React.Dispatch<React.SetStateAction<boolean>>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            if (!file) return
            const fileType = file.type;

            if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType === "application/vnd.ms-excel") {
                setFileName(file.name);
                setFileUploaded(true)
            } else {
                setFileName("Tipo de archivo no permitido")
                setFileUploaded(false);
            }
        }
    };

    return (
        <Flex width={"100%"} justifyContent={"center"}>
            <label>
                <Box
                    border="2px dashed"
                    borderColor="gray.200"
                    borderRadius="md"
                    p={20}
                    textAlign="center"
                    mr={10}
                    width={"300px"}

                >
                    <Icon as={FaFileUpload} boxSize="24px" color="gray.400"/>
                    <Text mt={2}>Intüipos</Text>
                    <Text fontSize={"x-small"} color={"grey"}>Click aquí para subir archivo</Text>
                    <Input
                        type="file"
                        id={"intuipos"}
                        onChange={(e) => handleFileUpload(e, setFile1Name, setFile1Uploaded)}
                        display="none"
                    />
                </Box>
                <Box mt={"5px"} width={"300px"} overflow="hidden" textOverflow="ellipsis" whiteSpace="wrap"
                     textAlign={"center"}>
                    <Text fontSize="sm" fontWeight="bold" color="blue.500">{file1Name}</Text>
                </Box>
            </label>
            <label>
                <Box
                    border="2px dashed"
                    borderColor="gray.200"
                    borderRadius="md"
                    p={20}
                    width={"300px"}
                    textAlign="center"
                >
                    <Icon as={FaFileUpload} boxSize="24px" color="gray.400"/>
                    <Text mt={2}>DIAN</Text>
                    <Text fontSize={"x-small"} color={"grey"}>Click aquí para subir archivo</Text>
                    <Input
                        type="file"
                        id={"dian"}
                        onChange={(e) => handleFileUpload(e, setFile2Name, setFile2Uploaded)}
                        display="none"
                    />
                </Box>
                <Box mt={"5px"} width={"300px"} overflow="hidden" textOverflow="ellipsis" whiteSpace="wrap"
                     textAlign={"center"}>
                    <Text fontSize="sm" fontWeight="bold" color="blue.500">{file2Name}</Text>
                </Box>

            </label>
        </Flex>
    )
}

export default UploadComparisonFiles;