import {Box, Icon, Text, VStack} from "@chakra-ui/react";
import {useAppSelector} from "../../store/store";
import {ViewIcon} from "@chakra-ui/icons";

export const PDFViewer = () => {

    const {invoice} = useAppSelector((state) => state.invoices)
    const pdfUrl = invoice?.pdf_url

    return (
        <Box bg={"lightgrey"} borderRadius={"10px"} height={"60%"} width={"100%"} display="flex" justifyContent="center" alignItems="center">
                {pdfUrl ? (
                    <iframe
                        src={pdfUrl}
                        title={"myFrame"}
                        width="100%"
                        height="100%"
                        style={{border: "none", borderRadius: "10px"}}
                    />
                ) : (
                    <VStack><Icon as={ViewIcon} boxSize={"100px"} color={"grey"}/>
                        <Text color={"grey"}>No se ha seleccionado un PDF</Text></VStack>

                )}
        </Box>)
}