import React from "react";
import {Box, Flex, Icon, Text, VStack} from "@chakra-ui/react";

interface NomenclatureProps {
    description: string;
    icon: any;
    boxColor: string;
}

const NomenclatureItem: React.FC<NomenclatureProps> = ({description, icon, boxColor}) => {
    return (
        <Flex>
            <Flex alignItems="center" justifyContent="center" mr={"10px"}>
                <Box borderRadius={"50%"} bg={boxColor} width={"20px"}
                     height={"20px"} alignContent={"center"}>
                    <VStack>
                        <Icon as={icon} boxSize={"10px"} color={"white"}/>
                    </VStack>

                </Box>
            </Flex>
            <Text>
                {description}
            </Text>

        </Flex>
    )
}

export default NomenclatureItem;