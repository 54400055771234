import {createSlice} from '@reduxjs/toolkit'
import {Invoice} from "../types/invoice.types";

export interface InvoiceState {
    invoice: null | Invoice
}

const initialState: InvoiceState = {
    invoice: null,
}

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        selectInvoice: (state, action) => {
            state.invoice = action.payload
        }

    },
})

export default invoiceSlice.reducer