import {
  Box,
  Button,
  Checkbox,
  Flex,
  GridItem,
  Heading,
  Icon,
  List,
  ListItem,
  SimpleGrid,
  Spacer,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InvoiceCard from "../components/invoicing/InvoiceCard";
import React, { useEffect, useState } from "react";
import { SelectedInvoice } from "../components/invoicing/SelectedInvoice";
import {
  fetchInvoices,
  uploadInvoicesIntuipos, uploadInvoicesSiigo,
} from "../services/invoices.services";
import { PDFViewer } from "../components/invoicing/PDFViewer";
import { FaFileInvoice } from "react-icons/fa";
import "../styles/main.css";

// TODO: Fix proportions error for the grid and background

export default function Invoices() {
  const [invoices, setInvoices] = useState<any[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<any[]>([]);
  const toast = useToast();
  const handleSelectInvoice = (invoice: any) => {
    if (selectedInvoices.includes(invoice.id)) {
      let filteredInvoices = selectedInvoices.filter(
        (item) => item !== invoice.id
      );
      setSelectedInvoices(filteredInvoices);
    } else {
      setSelectedInvoices([...selectedInvoices, invoice.id]);
    }
  };

  // const deleteInvoice = (invoiceId: string) => {
  //     setInvoices(invoices.filter(i => i.invoice_number !== invoiceId))
  //     deleteInvoiceById(invoiceId)
  // }

  useEffect(() => {
    fetchInvoices().then((data) => {
      if (data) {
        setInvoices(data);
      }
    });
  }, []);

  return (
    <Box paddingLeft={"10px"} height={"100%"}>
      <Heading>
        <Flex>
          <Text fontWeight={"500"}>Facturas</Text> <Spacer />
          <Button
            mr={"10px"}
            bg={"blue.400"}
            color={"white"}
            _hover={{ bg: "blue.600" }}
            onClick={(e) => {
              uploadInvoicesIntuipos(selectedInvoices).then((data) => {
                console.log({ data });
                let description;
                if (data.status === "success") {
                  description = `Facturas subidas correctamente ${data.successfully_uploaded.length} \n Facturas con error ${data.error_uploaded.length}`;
                } else {
                  description = "No se han seleccionado facturas para subir";
                }

                toast({
                  position: "top-right",
                  title: "Facturas subidas a Intuipos",
                  description: description,
                  status: data.status,
                  duration: 12000,
                  isClosable: true,
                });
              });
            }}
          >
            Subir facturas a Intuipos
          </Button>
          <Button
              mr={"10px"}
              bg={"blue.400"}
              color={"white"}
              _hover={{ bg: "blue.600" }}
              onClick={(e) => {
                uploadInvoicesSiigo(selectedInvoices).then((data) => {
                  let description;
                  if (data.status === "success") {
                    description = `Facturas subidas correctamente ${data.successfully_uploaded.length} \n Facturas con error ${data.error_uploaded.length}`;
                  } else {
                    description = "No se han seleccionado facturas para subir";
                  }

                  toast({
                    position: "top-right",
                    title: "Facturas subidas a Siigo",
                    description: description,
                    status: data.status,
                    duration: 12000,
                    isClosable: true,
                  });
                });
              }}
          >
            Subir facturas a Siigo
          </Button>
        </Flex>
      </Heading>
      <SimpleGrid
        className={"container-test"}
        columns={2}
        mt={"25px"}
        spacing={"15px"}
      >
        <GridItem>
          <Box
            bg={"lightgrey"}
            color={"white"}
            className={"container-test"}
            padding={"10px"}
            borderRadius={"10px"}
          >
            {invoices.length === 0 ? (
              <VStack align="center" justify="center" height="100%">
                <Icon as={FaFileInvoice} boxSize="100px" color={"grey"} />
                <Text color={"grey"}>No se han encontrado facturas aún</Text>
              </VStack>
            ) : (
              <List
                overflowY={"scroll"}
                display={"flex"}
                flexDirection={"column"}
                height={"100%"}
                spacing={2}
              >
                {invoices.map((invoice, index) => (
                  <ListItem marginRight={"8px"} key={index}>
                    <Flex>
                      <Checkbox
                        mr={2}
                        isChecked={
                          selectedInvoices
                            ? selectedInvoices.includes(invoice.id)
                            : false
                        }
                        onChange={() => handleSelectInvoice(invoice)}
                      />
                      <InvoiceCard invoice={invoice} />
                    </Flex>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </GridItem>
        <GridItem>
          <Flex
            color={"white"}
            borderRadius={"10px"}
            flexDirection={"column"}
            className={"container-test"}
          >
            <SelectedInvoice />
            <Spacer margin={"1%"} />
            <PDFViewer />
          </Flex>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
}
