import config from "../config/env.config";
import {getToken} from "../utils/auth";

export const fetchBizName = async (): Promise<any | undefined> => {
    try {
        const token = getToken();
        // console.log('token', token);
        const response = await fetch(`${config.apiUrl}/biz_name`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json()
        return data.biz_name;
    } catch (error) {
        console.error('Failed to fetch protected data:', error);
    }
};