import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import UploadSales from "../components/siigo_upload/UploadSales";
import UploadPurchases from "../components/siigo_upload/UploadPurchases";

export default function SiigoUpload() {
    return (
        <Tabs isFitted variant='enclosed'>
            <TabList mb='1em'>
                <Tab>Compras</Tab>
                <Tab>Ventas</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <UploadPurchases/>
                </TabPanel>
                <TabPanel>
                    <UploadSales/>
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
}