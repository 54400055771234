import config from "../config/env.config";
import { getToken } from "../utils/auth";
import {InvoiceResponse} from "../types/invoice.types";
import {InventoryItemsResponse} from "../types/inventory.types";


export const fetchInvoices = async () => {
    try {
        const token = getToken();
        const response = await fetch(`${config.apiUrl}/invoices`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json() as InvoiceResponse;
        return data.invoices;
    } catch (error) {
        console.error('Failed to fetch invoices:', error);
    }
};

export const deleteInvoiceById = async (invoiceId: string) => {
    try {
        const token = getToken();
        await fetch(`${config.apiUrl}/invoices/${invoiceId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        console.error('Failed to delete invoice:', error);
    }
};

export const uploadInvoicesIntuipos = async (invoicesList: any) => {
    try {
        const token = getToken();
        const response = await fetch(`${config.apiUrl}/intuipos_upload`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ invoices: invoicesList }),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to upload invoices:', error);
    }
};

export const uploadInvoicesSiigo = async (invoicesList: any) => {
    try {
        const token = getToken();
        const response = await fetch(`${config.apiUrl}/siigo_upload_invoices`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ invoices: invoicesList }),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to upload invoices:', error);
    }
};

export const fetchInventoryItems = async () => {
    try {
        const token = getToken();
        const response = await fetch(`${config.apiUrl}/inventory_items`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as InventoryItemsResponse;
    } catch (error) {
        console.error('Failed to fetch inventory items:', error);
    }
};

export const updateInvoice = async ({ invoice }: any) => {
    try {
        const token = getToken();
        console.log(invoice)
        const response = await fetch(`${config.apiUrl}/invoice_items_update`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ invoice: invoice }),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to update invoice:', error);
    }
};