import {
    Box,
    Button,
    Divider,
    GridItem,
    Heading,
    Modal,
    SimpleGrid,
    Stack,
    Text,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import React, {useRef} from "react";
import {useAppSelector} from "../../store/store";
import ItemsModal from "./ItemsModal";

export const SelectedInvoice = () => {
    const {invoice} = useAppSelector((state) => state.invoices)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const btnRef = useRef(null)
    const [expandCUFE, setExpandCUFE] = React.useState(false)

    return (
        <Box bg={"blue.500"} minHeight={"39%"} borderRadius={"10px"} p={"15px"}>
            <Stack alignItems={"center"}>
                <Heading color={"white"}>
                    Información de la factura
                </Heading>

            </Stack>
            <Divider border={"1px"} borderColor={"white"} mt={"10px"}></Divider>
            <SimpleGrid columns={2} py={"30px"} height={"70%"} px={"20px"} overflowY={expandCUFE ? "auto" : "hidden"} templateColumns={"2fr 1fr"}>
                <GridItem color={"white"} maxWidth="100%">
                    <VStack alignItems={"start"} spacing={3}>
                        {
                            invoice?.invoice_cufe ? 
                            (
                            <Text onClick={() => setExpandCUFE((prev) => !prev)} whiteSpace="normal" overflowWrap="break-word" wordBreak="break-all">
                                <Box  as="span" fontWeight="bold">CUFE de factura:</Box> { expandCUFE ?  invoice?.invoice_cufe : `${invoice?.invoice_cufe.slice(0, 10)}...` }
                            </Text> 
                            ) : 
                            (    
                            <Text whiteSpace="normal" overflowWrap="break-word" wordBreak="break-all">
                                <Box as="span" fontWeight="bold">CUFE de factura:</Box> 
                            </Text>
                            )
                        }
                        <Text whiteSpace="normal" overflowWrap="break-word">
                            <Box as="span" fontWeight="bold">NIT del proveedor: </Box> {invoice?.sender_party}
                        </Text>
                        {/*<Text whiteSpace="normal" overflowWrap="break-word">*/}
                        {/*    <Box as="span" fontWeight="bold">Total a pagar:</Box> {formatCurrency(invoice?.total_amount_to_pay)}*/}
                        {/*</Text>*/}
                    </VStack>
                </GridItem>
                <GridItem color={"white"}>
                    <VStack alignItems={"center"} spacing={3}>
                        <Button mt={3} ref={btnRef} onClick={onOpen} hidden={!invoice}>
                            Ver productos
                        </Button>

                        <Modal
                            onClose={onClose}
                            finalFocusRef={btnRef}
                            isOpen={isOpen}
                            scrollBehavior={'inside'}
                            size={'xl'}
                        >
                            <ItemsModal invoice={invoice} onClose={onClose} />
                        </Modal>
                    </VStack>
                </GridItem>
            </SimpleGrid>

        </Box>)
}