import config from "../config/env.config";
import { getToken } from "../utils/auth";
import {ComparisonModel, DianComparisonResponse} from "../types/dian.types";


export const fetchComparison = async (): Promise<Array<ComparisonModel> | undefined> => {
    const fileInput1 = document.querySelector<HTMLInputElement>('#intuipos');
    const fileInput2 = document.querySelector<HTMLInputElement>('#dian');

    if (!fileInput1 || !fileInput2 || !fileInput1.files || !fileInput2.files) {
        console.error('File inputs or files are not available');
        return;
    }

    const file1 = fileInput1.files[0];
    const file2 = fileInput2.files[0];

    if (!file1 || !file2) {
        console.error('Files are not available');
        return;
    }

    // Create new File objects with the new names
    const newFile1 = new File([file1], "intuipos_" + file1.name, {type: file1.type});
    const newFile2 = new File([file2], "dian_" + file2.name, {type: file2.type});

    const formData = new FormData();
    formData.append('file1', newFile1);
    formData.append('file2', newFile2);
    try {
        const token = getToken();
        const response = await fetch(`${config.apiUrl}/dian_comparison`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData
        });
        const data = await response.json() as DianComparisonResponse;
        return data.results;
    } catch (error) {
        console.error('Failed to fetch invoices:', error);
    }
};