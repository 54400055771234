import * as React from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import Invoices from "./pages/Invoices";
import RootLayout from "./layouts/RootLayout";
import Dian from "./pages/Dian";
import SiigoUpload from "./pages/SiigoUpload";
import Login from "./pages/Login";
import PrivateRoute from "./components/utils/PrivateRoute";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<RootLayout />}>
                <Route index element={<PrivateRoute><Invoices /></PrivateRoute>} />
                <Route path="dian" element={<PrivateRoute><Dian /></PrivateRoute>} />
                <Route path="purchase_sales" element={<PrivateRoute><SiigoUpload /></PrivateRoute>} />
            </Route>
        </>
    )
);

export default function App() {
    return (
        <RouterProvider router={router} />
    );
}