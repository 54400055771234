import { useEffect, useState } from "react";
import { Flex, Heading, HStack, Spacer, Text } from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import { useNavigate } from "react-router-dom";
import {fetchBizName} from "../../services/layout.services"; // Assume this is the file where the request is made

export default function Navbar() {
    const navigate = useNavigate();
    const [bizName, setBizName] = useState("");
    const getBizName = async () => {
        try {
            const name = await fetchBizName();
            setBizName(name);
        } catch (error) {
            console.error("Error fetching heading name: ", error);
        }
    };

    useEffect(() => {
        getBizName();
    }, []);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate("/login");
        } catch (error) {
            console.error("Error logging out: ", error);
        }
    };

    return (
        <Flex as="nav" p="10px" mb="10px" alignItems="center">
            <Heading>{bizName}</Heading>
            <Spacer />
            <HStack spacing="20px">
                <Text
                    cursor="pointer"
                    onClick={handleLogout}
                    _hover={{ textDecoration: "underline", color: "blue.400" }}
                >
                    Cerrar Sesión
                </Text>
            </HStack>
        </Flex>
    );
}