import {Box, Button, List, ListIcon, ListItem, useColorModeValue} from "@chakra-ui/react"
import {NavLink, useLocation} from "react-router-dom"
import {FaFileInvoice, FaMoneyBill} from "react-icons/fa";
import {ExternalLinkIcon} from "@chakra-ui/icons";


// @ts-ignore
const NavButton = ({to, children}) => {
    const location = useLocation();
    const isActive = location.pathname === to;
    const bg = useColorModeValue("blue.400", "blue.600");
    const color = "white";

    return (
        <NavLink to={to}>
            <Button width={"100%"} bg={!isActive ? bg : undefined} color={!isActive ? color : undefined}
                    justifyContent={"flex-start"}>
                {children}
            </Button>
        </NavLink>
    );
};

export default function Sidebar() {
    return (
            <List color="white" fontSize="1.2em" spacing={4}>
                <ListItem>
                    <NavButton to="/">
                        <ListIcon as={FaFileInvoice}/>
                        Facturas
                    </NavButton>
                </ListItem>
                <ListItem>
                    <NavButton to="/purchase_sales">
                        <ListIcon as={ExternalLinkIcon}/>
                        Compras y Ventas
                    </NavButton>
                </ListItem>
                <ListItem>
                    <NavButton to="/dian">
                        <ListIcon as={FaMoneyBill}/>
                        DIAN
                    </NavButton>
                </ListItem>

            </List>
    )
}
