import {Box, Card, Flex, Icon, Text, VStack} from "@chakra-ui/react";
import {CheckIcon, CloseIcon, QuestionIcon} from "@chakra-ui/icons";
import React from "react";
import {setComparisonSelected} from "../../actions/dian.actions";
import {useAppDispatch} from "../../store/store";

interface ComparisonCardProps {
    folioDian: string;
    folioIntuipos: string;
    status: string;
}

const ComparisonCard = ({folioDian, folioIntuipos, status}: ComparisonCardProps) => {
    const dispatch = useAppDispatch();

    let icon;
    let color;

    switch (status) {
        case 'match':
            icon = CheckIcon;
            color = 'green';
            break;
        case 'not-match':
            icon = CloseIcon;
            color = 'red';
            break;
        case 'not-found':
            icon = QuestionIcon;
            color = 'black';
            break;
        default:
            icon = CheckIcon;
            color = 'green';
    }


    return (
        <Card
            height={"105px"}
            p={"20px"}
            overflow='hidden'
            variant='elevated'
            cursor="pointer"
            _hover={{boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
            onClick={() => {
                dispatch(setComparisonSelected(folioDian))
            }}
        >
            <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text fontSize={"large"} fontWeight={"bold"}>
                    DIAN
                </Text>

                <Text fontSize={"large"} fontWeight={"bold"}>
                    Intüipos
                </Text>

            </Flex>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text>
                    {folioDian}
                </Text>
                <Box width={"100%"} bg={color} height={"1.5px"} mx={"4px"}></Box>
                <Flex alignItems="center" justifyContent="center">
                    <Box borderRadius={"50%"} bg={color} width={"30px"}
                         height={"30px"} alignContent={"center"}>
                        <VStack>
                            <Icon as={icon} boxSize={"13px"} color={"white"}/>
                        </VStack>

                    </Box>
                </Flex>
                <Box width={"100%"} bg={color} height={"1.5px"} mx={"4px"}></Box>

                {folioIntuipos ? (
                    <Text>
                        {folioIntuipos}
                    </Text>
                ) : (<Text color={"lightgrey"}>{folioDian}</Text>)}

            </Flex>

        </Card>
    )
}

export default ComparisonCard;