import {Box, Flex, GridItem, SimpleGrid, Text} from "@chakra-ui/react";
import ComparisonDetailRow from "./ComparisonDetailRow";
import {useAppSelector} from "../../store/store";
import {formatCurrency} from "../../utils/currencyFormat";

// TODO: Fix UI. It doesn't make sense if I always compare with dian and don't know the intuipos wrong value

const ComparisonDetail = () => {
    const {comparisonResult, comparisonSelected} = useAppSelector((state) => state.dian)

    const selectedComparison = comparisonResult.filter((result: any) => result['folio_dian'] === comparisonSelected)[0]

    return (
        <Box height={"100%"} borderRadius={"10px"} border={"2px"} borderColor={"lightgrey"}>
            <Box height={"60px"} bg={"blue.400"}>
                <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
                    <Text fontWeight={"bold"} color={"white"} fontSize={"x-large"}>Comparación</Text>
                </Flex>
            </Box>

            <Box p={"20px"}>
                <SimpleGrid columns={6} spacing={4}>
                    <GridItem colSpan={2}>
                        <Text fontSize={"large"} fontWeight={"bold"}>
                            Descripción
                        </Text>
                    </GridItem>
                    <GridItem colSpan={2}>
                        <Text fontSize={"large"} fontWeight={"bold"}>
                            Valor
                        </Text>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <Text textAlign={"center"} fontSize={"large"} fontWeight={"bold"}>
                            Intüipos
                        </Text>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <Text fontSize={"large"} fontWeight={"bold"} textAlign={"center"}>
                            DIAN
                        </Text>
                    </GridItem>
                    {selectedComparison ? (
                        <>
                            <ComparisonDetailRow description={"Numero de documento"}
                                                 value={`${selectedComparison.folio_dian}`}
                                                 intuipos={true} dian={true} intuiposValue={''} dianValue={''}/>
                            <ComparisonDetailRow description={"Cufe/Cude"} value={selectedComparison.cufe_cude}
                                                 intuipos={true}
                                                 dian={true} intuiposValue={''} dianValue={''}/>
                            <ComparisonDetailRow description={"Fecha de emisión"}
                                                 value={selectedComparison.issuing_date_dian}
                                                 intuipos={true} dian={true} intuiposValue={''} dianValue={''}/>
                            <ComparisonDetailRow description={"NIT"} value={`${selectedComparison.nit_dian}`} intuipos={true}
                                                 dian={true} intuiposValue={''} dianValue={''}/>
                            <ComparisonDetailRow description={"Nombre del emisor"}
                                                 value={selectedComparison.issuing_name}
                                                 intuipos={true} dian={true} intuiposValue={''} dianValue={''}/>
                            <ComparisonDetailRow description={"IPC"} value={formatCurrency(`${selectedComparison.ipc_dian}`)}
                                                 intuipos={selectedComparison.ipc_match} dian={true}
                                                 intuiposValue={selectedComparison.original_ipc_intuipos.toString()}
                                                 dianValue={selectedComparison.original_ipc_dian.toString()}/>
                            <ComparisonDetailRow description={"IVA"} value={formatCurrency(`${selectedComparison.iva_dian}`)}
                                                 intuipos={selectedComparison.iva_match} dian={true}
                                                 intuiposValue={selectedComparison.original_iva_intuipos.toString()}
                                                 dianValue={selectedComparison.original_iva_dian.toString()}/>
                            <ComparisonDetailRow description={"Total"}
                                                 value={formatCurrency(`${selectedComparison.total_dian}`)}
                                                 intuipos={selectedComparison.total_match} dian={true}
                                                 intuiposValue={selectedComparison.original_total_intuipos.toString()}
                                                 dianValue={selectedComparison.original_total_dian.toString()}/></>
                    ) : (
                        <>
                            {/*TODO: Implement empty state for the table*/}
                        </>
                    )}

                </SimpleGrid>
            </Box>
        </Box>
    )
}

export default ComparisonDetail