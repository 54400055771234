import { VStack, Heading, Input, Button, Box, Text, useToast, Image } from "@chakra-ui/react";
import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/firebaseConfig";

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [validationError, setValidationError] = useState("");
    const navigate = useNavigate();
    const toast = useToast();

    const validateInputs = () => {
        if (!email) {
            setValidationError("El correo es obligatorio.");
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            setValidationError("El correo no es válido.");
            return false;
        }
        if (!password) {
            setValidationError("La contraseña es obligatoria.");
            return false;
        }
        if (password.length < 6) {
            setValidationError("La contraseña debe tener al menos 6 caracteres.");
            return false;
        }
        setValidationError("");
        return true;
    };

    const handleLogin = async () => {
        if (!validateInputs()) return;

        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate("/");
        } catch (error: any) {
            setError('Correo o contraseña incorrectos.');
            toast({
                title: "Error",
                description: "Correo o contraseña incorrectos.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <VStack spacing={4} align="center" justify="center" height="100vh" bgGradient="linear(to-r, blue.500, blue.300)">
            <Box p={8} maxWidth="400px" borderWidth={1} borderRadius={8} boxShadow="lg" bg="white" animation="ease-in-out 0.5s">
                <VStack spacing={4}>
                    <Heading as="h1" size="lg" mb={6}>
                        Iniciar Sesión
                    </Heading>
                    {error && <Text color="red.500">{error}</Text>}
                    {validationError && <Text color="red.500">{validationError}</Text>}
                    <Input placeholder="Correo" type="email" value={email} onChange={(e) => setEmail(e.target.value)} focusBorderColor="blue.400" />
                    <Input placeholder="Contraseña" type="password" value={password} onChange={(e) => setPassword(e.target.value)} focusBorderColor="blue.400" />
                    <Button colorScheme="blue" width="full" onClick={handleLogin} _hover={{ bg: "blue.600" }}>
                        Login
                    </Button>
                </VStack>
            </Box>
        </VStack>
    );
}